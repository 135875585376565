<template>
  <div class="OrderDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div>
        <DataCard
          :data="items"
          :fields="fields"
          :actions="actions"
          @tarifs="showModalTarifs"
        />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-my-lg">
      <div class="col-auto">
        <q-btn color="green" icon="check" label="Aprovar" @click="approve" />
      </div>
      <div class="col-auto">
        <q-btn color="red" icon="block" label="Rejeitar" @click="reject" />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />

    <div class="DetailTarifs">
      <q-dialog v-model="modalTarifs" persistent full-width full-height>
        <div class="bg-white absolute-full">
          <div v-if="negOrLink == 'tabela'" class="text-h5 q-py-md text-center">
            Detalhamento da Tabela Vínculo
          </div>
          <div v-else class="text-h5 q-py-md text-center">
            Detalhamento das Tarifas
          </div>

          <div class="q-pb-md flex justify-evenly">
            <q-btn
              icon="account_tree"
              label="Ver Tabela"
              class="bg-blue-10 text-white"
              @click="viewTableCard = true"
            />
            <q-btn
              icon="account_tree"
              label="Ver Cartões"
              class="bg-blue-10 text-white"
              @click="viewTableCard = false"
            />
          </div>

          <div v-if="viewTableCard" class="q-px-lg">
            <DataTable
              :data="negOrLink == 'tabela' ? dataLinkedTable : dataTarifs"
              :columns="negOrLink == 'tabela' ? colsLinkedTable : colsTarifs"
              :gridMobile="false"
              hidePagination
              class="q-mx-sm table"
              style="margin-bottom: 25px"
            />
          </div>
          <div v-else class="q-px-lg">
            <DataCard
              :data="negOrLink == 'tabela' ? dataLinkedTable : dataTarifs"
              :fields="negOrLink == 'tabela' ? colsLinkedTable : colsTarifs"
            />
          </div>

          <div
            class="q-py-md flex justify-center"
            style="width: 100vw; position: absolute; bottom: 0px;"
          >
            <q-btn
              label="Fechar"
              color="blue-10"
              @click="modalTarifs = false"
            />
          </div>
        </div>
      </q-dialog>
    </div>

    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Tabela GFE</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTable";
// import productsCard from "./productsCard";
import ApprovalHierarchy from "./ApprovalHierarchy";
import formatString from "@/helpers/formatString";
import DataCard from "@/components/DataCard";

export default {
  components: {
    DataTable,
    DataCard,
    // productsCard,
    ApprovalHierarchy
  },
  data() {
    return {
      modalReason: false,
      modalTarifs: false,
      descriptionRejection: null,
      gfe: null,
      items: null,
      viewTableCard: true,
      dataTarifs: null,
      dataLinkedTable: null,
      negOrLink: null,
      colsLinkedTable: [
        {
          field: "tableCode",
          label: "Tabela",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "carrierCode",
          label: "Código",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "carrierName",
          label: "Fornecedor",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "carrierIdfed",
          label: "CNPJ",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "additional",
          label: "Adicional",
          type: "number",
          align: "left",
          sortable: true
        },
        {
          field: "multiplier",
          label: "Multiplicador",
          type: "number",
          align: "left",
          sortable: true
        }
      ],
      colsTarifs: [
        {
          field: "component",
          label: "Tipo",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "description",
          label: "Descrição",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "estado",
          label: "UF",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "fraction_norm",
          label: "Fração",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "percent_normal",
          label: "Percentual",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "vl_fixo_ext",
          label: "Valor Fixo Ext.",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "vl_fixo_normal",
          label: "Valor Fixo Normal",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "vl_limite",
          label: "Valor Limite",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "vl_min_norm",
          label: "Valor Mínimo",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "vl_unit_nor",
          label: "Valor Unit.",
          type: "number",
          align: "center",
          sortable: true
        },
        {
          field: "route",
          label: "Rota",
          type: "string",
          align: "center",
          sortable: true
        },
        {
          field: "name_route",
          label: "Descrição da Rota",
          type: "string",
          align: "center",
          sortable: true
        }
      ],
      fields: [
        {
          field: "codeNumber",
          label: "",
          type: "string",
          class: "text-bold"
        },
        {
          field: "term",
          label: "",
          type: "string",
          class: "text-overline"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "countTarifs",
          label: "",
          type: "string",
          class: "text-body2"
        }
      ],
      actions: [
        {
          label: "Visualizar",
          color: "blue-10",
          icon: "visibility",
          eventEmit: "tarifs"
        }
      ]
    };
  },
  computed: {
    gfeId() {
      return this.$route.params.gfeId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("gfeApprovalTable", [
      "getGFEById",
      "approvesRejectsGFETable"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    formatString,
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.gfe.approvalHierarchy);
    },
    approve() {
      this.sendResponse(this.gfe, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.modalReason = true;
    },
    sendResponse(gfe, response, text, classMessage) {
      console.log(gfe);
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> a Tabela ${gfe.tableCode}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsGFETable({
              gfe,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "gfe" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    confirmReject() {
      if (
        String(this.descriptionRejection).length < 8 ||
        this.descriptionRejection === null ||
        this.descriptionRejection === ""
      ) {
        this.$q.dialog({
          title: "Cicopal Aprova",
          message: "Favor detalhar o motivo da Rejeição deste pedido!"
        });

        return;
      }

      this.sendResponse(this.gfe, "rejected", "REJEITAR", "text-red");
    },
    showModalTarifs(negociation) {
      const dataType = String(negociation.codeNumber).includes("Negoc");

      if (dataType) {
        this.negOrLink = "negociacao";
        this.dataTarifs = negociation?.tarifs;
      } else {
        const tempData = [[negociation?.tarifs]];
        this.negOrLink = "tabela";
        this.dataLinkedTable = tempData;
      }

      this.modalTarifs = true;
    }
  },
  async created() {
    try {
      const gfe = await this.getGFEById(this.gfeId);

      const negociations = gfe?.negociations.map(item => ({
        ...item,
        id: this.gfeId,
        countTarifs: `${parseInt(item.tarifs.length)} tarifas`,
        codeNumber: `Negociação: ${item.negociation}`,
        term: `${formatString(
          item.initialTerm.trim() || "19000101",
          "date"
        )} - ${formatString(item.finalTerm.trim() || "20991231", "date")}`
      }));

      const newGFE = { ...gfe, id: this.gfeId };

      // ### SE FORMOS UTILIZAR A OPÇÃO DE TABELA VINCULO PRECISO ATIVAR ESTE CODIGO
      // const linkeds = gfe?.linkedTable.map(item => ({
      //   ...item,
      //   codeNumber: `Tabela Vínculo: ${item.tableCode}`,
      //   countTarifs: item.carrierIdfed,
      //   term: `${item.carrierCode} - ${item.carrierName}`,
      //   tarifs: [item]
      // }));

      // const items = [...linkeds, ...negociations];

      const items = [...negociations];

      this.items = items;
      this.gfe = newGFE;
    } catch (error) {
      console.warn(error);
      this.$router.back();
    }
  }
};
</script>
<style lang="scss">
.OrderDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
